import service1 from "../images/service1.png";
import service2 from "../images/service2.png";
import service3 from "../images/service3.png";
import service4 from "../images/service4.png";
import service5 from "../images/service5.png";
import service6 from "../images/service1.png";
import service7 from "../images/service2.png";
import service8 from "../images/service3.png";
export const ServiceData = [
  {
    id: 1,
    name: "Ocean Shipping",
    img: service1,
    para: "There are many variations passages Lorem Ipsum, but we are the we are no majority have.",
  },
  {
    id: 2,
    name: "Air Cargo Service",
    img: service2,
    para: "There are many variations passages Lorem Ipsum, but we are the we are no majority have.",
  },
  {
    id: 3,
    name: "Road Transport",
    img: service4,
    para: "There are many variations passages Lorem Ipsum, but we are the we are no majority have.",
  },
  {
    id: 4,
    name: "Warehouse & Distribution",
    img: service4,
    para: "There are many variations passages Lorem Ipsum, but we are the we are no majority have.",
  },
  {
    id: 5,
    name: "Freight Service",
    img: service5,
    para: "There are many variations passages Lorem Ipsum, but we are the we are no majority have.",
  },
  {
    id: 6,
    name: "Project Cargo Service",
    img: service6,
    para: "There are many variations passages Lorem Ipsum, but we are the we are no majority have.",
  },
  {
    id: 7,
    name: " Customs Brokerage",
    img: service7,
    para: "There are many variations passages Lorem Ipsum, but we are the we are no majority have.",
  },
  {
    id: 8,
    name: "Rail Freight Services",
    img: service8,
    para: "There are many variations passages Lorem Ipsum, but we are the we are no majority have.",
  },

];

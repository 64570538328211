import React from "react";
import { BrandData } from "../Data/BrandData";
import { motion } from "framer-motion";

const Brands = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="text-center mb-12">
          <p>We are very fortunate to work with these amazing partners</p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 xl:flex flex-wrap justify-between sm:gap-5 xl:gap-0">
          {BrandData.map((e, i) => (
            <motion.div
              initial={{ x: 50, y: 50, opacity: 0, scale: 0.5 }}
              whileInView={{ x: 0, y: 0, opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: "100", delay: 0.1 * i }}
              className="bg-troo-white p-3 w-full xl:max-w-[207px]"
              key={i}
            >
              <img className="w-full" src={e.img} alt={e.img} />
            </motion.div>
          ))}
        </div>
        <div className="2xl:flex w-full xl:justify-center mt-5">
          <div className="grid grid-cols-2 md:grid-cols-4 xl:flex flex-wrap justify-between  sm:gap-5 xl:gap-0 w-full  xl:w-[84%]">
            {BrandData.slice(0, 5).map((e, i) => (
              <motion.div
                initial={{ x: 50, y: 50, opacity: 0, scale: 0.5 }}
                whileInView={{ x: 0, y: 0, opacity: 1, scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: "100",
                  delay: 0.2 * i,
                }}
                className="bg-troo-white p-3  w-full xl:max-w-[207px]"
                key={i}
              >
                <img className="w-full" src={e.img} alt={e.img} />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;

import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Brands from '../../Components/Brands'
import Testimonial from '../../Components/Testimonial'
import useDocumentTitle from '../../Hooks/PageTitle'

const TestimonialPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | Testimonial Page")
  return (
   <Layout>
    <BannerGlobal/>
<Testimonial/>
    <Brands/>
   </Layout>
  )
}

export default TestimonialPage
import React, { useEffect, useRef } from "react";
import logo from "../images/Logo.svg";
import logo2 from "../images/Logo2.svg";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { RiMenu3Fill } from "react-icons/ri";
import { HeaderData } from "../Data/HeaderData";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";
import AccordianMenu from "./AccordianMenu";
import { useLocation } from "react-router-dom";
import { PiChats } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";

const Header = () => {
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");

  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 10 || document.documentElement.scrollTop > 10) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="show_nav" ref={navbar_ref}>
        <input
          id="my-drawer-2"
          type="checkbox"
          className="drawer-toggle z-20"
        />
        <div className="main_container1 ">
          <nav className="py-4 lg:py-0">
            <div className="flex justify-between items-center">
              <div className="mr-7 xl:mr-24">
                <Link to="/">
                  <img src={logo2} alt="logo" />
                </Link>
              </div>
              <div className="lg:flex hidden items-center ">
                <ul className="flex items-center h-full text-troo-white">
                  {HeaderData.slice(0, 6).map((item, i) => {
                    return <NavMenu key={i} item={item} />;
                  })}
                  <div className="flex gap-5 items-center">
                    <div className="hidden lg:flex items-center">
                      <Link
                        to="/contact-us"
                        className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-white hover:text-troo-black"
                      >
                        GET A FREE QUOTE
                      </Link>
                    </div>
                  </div>
                </ul>
              </div>

              <div className="md:block lg:hidden  drawer-content">
                <label htmlFor="my-drawer-2">
                  <RiMenu3Fill className="text-[30px] text-troo-white cursor-pointer" />
                </label>
              </div>
            </div>
          </nav>
        </div>
        <div className="drawer-side z-100">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <div className="menu flex flex-col p-4 w-[80%] md:w-80 h-full overflow-scroll  bg-troo-primary text-troo-white">
            <div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
              <div className="flex items-center">
                <Link to="/">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-[200px] md:w-[200px] xl:w-[250px]"
                  />
                </Link>
              </div>
              <div className="flex w-full flex-col items-start gap-2 lg:gap-6 2xl:gap-8">
                {HeaderData.slice(0, 6).map((e, i) => {
                  return !e?.subNav ? (
                    <Link
                      key={i}
                      to={e.path}
                      className={`py-1 flex items-center capitalize text-lg ${
                        e.title === lastvalue ? "text-troo-primary" : ""
                      }`}
                    >
                      {e.title}
                    </Link>
                  ) : (
                    <AccordianMenu title={e.title} subMenu={e.subNav} />
                  );
                })}
              </div>
              <div className="">
                {HeaderData.slice(-1).map((e, i) => {
                  return (
                    <Link
                      key={i}
                      to={e.path}
                      className="bg-troo-secondary btn1 text-troo-white  "
                    >
                      {e.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="drawer drawer-end">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <header className="relative w-full left-[50%] translate-x-[-50%] z-20">
          <div className="text-center bg-troo-black py-3 text-troo-white">
            Welcome to TRoo Logistic Transportation Services
          </div>
          <div className="bg-troo-white">
            <div className="top_header main_container1 xl:flex hidden justify-between items-center flex-wrap pt-2 pb-16 ">
              <div className="mr-7 xl:mr-24">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="flex gap-10 justify-between">
                <div className="flex items-center gap-2">
                  <PiChats className="text-troo-secondary text-4xl " />
                  <div>
                    <p>+44 123 456 7890</p>
                    <p>contact@troothemes.com</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <IoLocationOutline className="text-troo-secondary text-4xl " />
                  <div>
                    <p>+44 123 456 7890</p>
                    <p>contact@troothemes.com</p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <FaFacebookF className="w-9 h-9 flex justify-center items-center bg-troo-primary hover:bg-troo-secondary p-2 transition-all duration-500 ease-in-out rounded-full text-troo-white" />
                  <FaInstagram className="w-9 h-9 flex justify-center items-center bg-troo-primary hover:bg-troo-secondary p-2 transition-all duration-500 ease-in-out rounded-full text-troo-white" />
                  <FaLinkedinIn className="w-9 h-9 flex justify-center items-center bg-troo-primary hover:bg-troo-secondary p-2 transition-all duration-500 ease-in-out rounded-full text-troo-white" />
                  <FaTwitter className="w-9 h-9 flex justify-center items-center bg-troo-primary hover:bg-troo-secondary p-2 transition-all duration-500 ease-in-out rounded-full text-troo-white" />
                </div>
              </div>
            </div>
          </div>
          <div className="main_container1 left-1/2 transform -translate-x-1/2 w-full xl:top-36 top-0 relative lg:absolute xl:rounded shadow bg-troo-primary">
            <nav className="py-4 lg:py-0">
              <div className="flex justify-between items-center">
                <div className="xl:hidden mr-7 xl:mr-24">
                  <Link to="/">
                    <img className="w-[180px] sm:w-auto" src={logo2} alt="logo" />
                  </Link>
                </div>
                <div className="lg:flex justify-between w-full hidden items-center ">
                  <ul className="flex items-center h-full text-troo-white">
                    {HeaderData.slice(0, 7).map((item, i) => {
                      return <NavMenu key={i} item={item} />;
                    })}
                  </ul>
                  <div className="hidden lg:flex items-center">
                    <Link
                      to="/contact-us"
                      className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-white hover:text-troo-black"
                    >
                      GET A FREE QUOTE
                    </Link>
                  </div>
                </div>
                <div className="md:block lg:hidden drawer-content">
                  <label htmlFor="my-drawer-2">
                    <RiMenu3Fill className="text-[30px] cursor-pointer text-troo-white" />
                  </label>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;

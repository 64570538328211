export const ServiceProcessData = [
     {
        id : 1,
        name : "Creative Analysis",
        para : "Lorem ipsum dolor sit amet it just dummy content, consectetur Lorem ipsum dolor sit amet."
     },
     {
        id : 2,
        name : "Design & Scatches",
        para : "Lorem ipsum dolor sit amet it just dummy content, consectetur Lorem ipsum dolor sit amet."
     },
     {
        id : 3,
        name : "Prototype & Wireframing",
        para : "Lorem ipsum dolor sit amet it just dummy content, consectetur Lorem ipsum dolor sit amet."
     },
     {
        id : 4,
        name : "Delivery & Deploy",
        para : "Lorem ipsum dolor sit amet it just dummy content, consectetur Lorem ipsum dolor sit amet."
     },
]
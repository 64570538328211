import { BsCheckLg } from "react-icons/bs";
import { RxCross2 } from 'react-icons/rx';


export const PricingData = [
  {
    id: 1,
    name: "Basic Plan",
    price: "33.00",
    feture: [
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><BsCheckLg/>10 Photos and Videos</>,
      },
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>Exclusive Support</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>No Custom Field</>,
      },
    ],
  },
  {
    id: 2,
    name: "Plus Plan",
    price: "66.00",
    feture: [
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><BsCheckLg/>10 Photos and Videos</>,
      },
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>Exclusive Support</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>No Custom Field</>,
      },
    ],
  },
  {
    id: 3,
    name: "Pro Plan",
    price: "89.00",
    feture: [
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><BsCheckLg/>10 Photos and Videos</>,
      },
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>Exclusive Support</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>No Custom Field</>,
      },
    ],
  },
  {
    id: 4,
    name: "Prime Plan",
    price: "99.00",
    feture: [
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><BsCheckLg/>10 Photos and Videos</>,
      },
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>Exclusive Support</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>No Custom Field</>,
      },
    ],
  },
  {
    id: 5,
    name: "Enterprise Plan",
    price: "125.00",
    feture: [
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><BsCheckLg/>10 Photos and Videos</>,
      },
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>Exclusive Support</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>No Custom Field</>,
      },
    ],
  },
  {
    id: 6,
    name: "Platinium Plan",
    price: "200.00",
    feture: [
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><BsCheckLg/>10 Photos and Videos</>,
      },
      {
        data: <><BsCheckLg/>10 GB Storage</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>Exclusive Support</>,
      },
      {
        data: <><RxCross2 className="text-troo-primary"/>No Custom Field</>,
      },
    ],
  },
];

import { FaStar } from 'react-icons/fa';
import client1 from "../images/client1.png"
import client2 from "../images/client2.png"
import client3 from "../images/client3.png"
import client4 from "../images/client4.png"
import client5 from "../images/client5.png"
import client6 from "../images/client6.png"
import client7 from "../images/client7.png"
import client8 from "../images/client8.png"
import client9 from "../images/client9.png"
import { BsStar } from 'react-icons/bs';


export const FeedbackData = [
    {
        id : 1,
        img : client1,
        name : "Harold Franky",
        Cdesg : "Marketing Head",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
    {
        id : 2,
        img : client2,
        name : "Morris Barton",
        Cdesg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
         rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
    {
        id : 3,
        img : client3,
        name : "Renny Young",
        Cdesg : "Business Consultant",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
         rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
    {
        id : 4,
        img : client4,
        name : "Seth Bechtelar",
        Cdesg : "Marketing Head",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
         rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
   {
        id : 5,
        img : client5,
        name : "Keith Satterfield",
        Cdesg : "Business Consultant",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
         rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
   {
        id : 6,
        img : client6,
        name : "Christie Bashirian",
        Cdesg : "Business Consultant",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
         rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
   {
        id : 7,
        img : client7,
        name : "Lynne Bauch",
        Cdesg : "Marketing Head",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
         rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
    {
        id : 8,
        img : client8,
        name : "Frederick Volkman",
        Cdesg : "Business Consultant",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
         rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
    {
        id : 9,
        img : client9,
        name : "Lewis Bernhard",
        Cdesg : "Business Consultant",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum.",
         rating : <><FaStar/><FaStar/><FaStar/><FaStar/><BsStar/></>
    },
]
import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { Link, createSearchParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";
import { motion } from "framer-motion";

const Teams = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(TeamData.slice(0, 3));
    } else {
      setData(TeamData);
    }
  }, []);
  return (
    <div
      className={`${
        isHome ? "bg-team-bg bg-no-repeat w-full h-full bg-center bg-cover" : ""
      } py-12 lg:py-24`}
    >
      <div className="main_container1">
        {isHome && (
          <div className="flex flex-col sm:flex-row gap-5 justify-between mb-12">
            <motion.div
              initial={{ x: -200 }}
              whileInView={{ x: 0 }}
              transition={{ duration: 2, type: "spring" }}
            >
              <Heading
                title={"Meet Our Team"}
                heading={
                  <>
                    Let’s Meet with our
                    <br /> logistic team member
                  </>
                }
              />
            </motion.div>
            <motion.div
              initial={{ x: 200 }}
              whileInView={{ x: 0 }}
              transition={{ duration: 2, type: "spring" }}
              className="flex sm:justify-end items-end"
            >
              <Link
                to="/our-team"
                className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary"
              >
                MEET ALL MEMBER
              </Link>
            </motion.div>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {data.map((e, i) => (
            <motion.div
            initial={{ x: -50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.3 * i }}
              key={i}
              className="bg-troo-tertiary p-3 rounded border border-troo-secondary border-opacity-10 hover:border-opacity-100 duration-500 ease-in-out transition-all"
            >
              <Link
                to={`/our-team/our-team-details?${createSearchParams({
                  id: e.id,
                })}`}
                className=" group flex flex-col gap-5 rounded"
              >
                <div className="flex justify-center items-center relative before:absolute before:content-[''] before:bg-troo-black before:w-full before:h-full before:bg-opacity-50 before:scale-0 group-hover:before:scale-100 before:transition-all before:duration-500 before:ease-in-out rounded">
                  <img className="rounded w-full" src={e.img} alt={e.name} />
                  <div className="absolute bg-troo-secondary flex p-2 gap-3 rounded scale-0 group-hover:scale-100 transition-all duration-500 ease-in-out">
                    {e.social.map((ele) => (
                      <Link
                        className="w-8 h-8 flex justify-center items-center rounded-full p-1 bg-troo-white hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-500"
                        to={ele.path}
                      >
                        {ele.icon}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="group-hover:bg-troo-secondary transition-all duration-500 ease-in-out flex flex-col gap-3 p-5 bg-troo-primary rounded text-troo-white">
                  <p>{e.sub}</p>
                  <h4>{e.name}</h4>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Teams;

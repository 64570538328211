import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Blogs from '../../Components/Blogs'
import Brands from '../../Components/Brands'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogPage = () => {
  useDocumentTitle("TRoo Logistics Transportation  | Blogs Page")
  return (
    <Layout>
        <BannerGlobal/>
        <Blogs/>
        <Brands/>
    </Layout>
  )
}

export default BlogPage
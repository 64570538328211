import blog1 from "../images/blog1.png";
import blog2 from "../images/blog2.png";
import blog3 from "../images/blog3.png";
import blog4 from "../images/blog4.png";
import blog5 from "../images/blog5.png";
import blog6 from "../images/blog6.png";
import blog7 from "../images/blog7.png";
import blog8 from "../images/blog8.png";

export const BlogData = [
  {
    id: 1,
    img: blog1,
    name: "Top 3 Logistics Outsourcing Mistakes You Don’t Want to Make",
    sub: "Logistics",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20 May, 2023",
  },
  {
    id: 2,
    img: blog2,
    name: "Sales & Operations Planning (S&OP) – The Greatest Success Factor",
    sub: "Logistics",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20 May, 2023",
  },
  {
    id: 3,
    img: blog3,
    name: "What To Look For in Heavy Machinery Shipping Companies",
    sub: "Logistics",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20 May, 2023",
  },
  {
    id: 4,
    img: blog4,
    name: "Flock Freight Appoints Orlando Baeza as Chief Revenue Officer",
    sub: "Logistics",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20 May, 2023",
  },
  {
    id: 5,
    img: blog5,
    name: "Using Trucking Spot Rates To Find Higher-Paying Loads",
    sub: "Logistics",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20 May, 2023",
  },
  {
    id: 6,
    img: blog6,
    name: "When Profits Follow Sustainability, Earth Day Becomes a True Celebration",
    sub: "Logistics",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20 May, 2023",
  },
  {
    id: 7,
    img: blog7,
    name: "Platform Updates For Faster Quotes & More Savings",
    sub: "Logistics",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20 May, 2023",
  },
  {
    id: 8,
    img: blog8,
    name: "12 Tips To Ace CVSA International Roadcheck 2023",
    sub: "Logistics",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20 May, 2023",
  },
];

import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <div className="bg-banner-bg bg-no-repeat bg-cover w-full h-full relative before:content-[''] before:bg-troo-secondary before:w-full before:h-full before:absolute before:opacity-60 before:top-0 before:z-[1] before:mix-blend-soft-light after:content-[''] after:absolute after:bg-troo-primary after:w-full after:h-full after:top-0 after:opacity-70">
      <div className="main_container1 py-12 lg:py-60 ">
        <div className="text-troo-white flex flex-col gap-8 relative z-[2]">
          <div className="flex gap-2 items-center">
            <div className="flex gap-1">
              <div className="w-6 h-[2px] bg-troo-white"></div>
              <div className="w-2 h-[2px] bg-troo-white"></div>
              <div className="w-2 h-[2px] bg-troo-white"></div>
            </div>
            <motion.p
              initial={{ x: 200 }}
              whileInView={{ x: 0 }}
              transition={{ duration: 2, type: "spring" }}
            >
              Professional Service
            </motion.p>
          </div>
          <motion.h1
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 2, type: "spring" }}
            className="max-w-[750px]"
          >
            We get job done, on time & reasonable cost.
          </motion.h1>
          <motion.p
            initial={{ x: 200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 2, type: "spring" }}
            className="max-w-[750px] "
          >
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            inta nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor
            sit.
          </motion.p>
          <div className="flex flex-col sm:flex-row gap-5">
            <motion.div
              initial={{ x: -200 }}
              whileInView={{ x: 0 }}
              transition={{ duration: 2, type: "spring" }}
            >
              <Link
                to="/our-services"
                className="btn1 bg-troo-secondary hover:bg-troo-white hover:text-troo-primary"
              >
                EXPLORE OUR SERVICES
              </Link>
            </motion.div>
            <motion.div
              initial={{ x: 200 }}
              whileInView={{ x: 0 }}
              transition={{ duration: 2, type: "spring" }}
            >
              <Link
                to="/about-us"
                className="btn1 bg-troo-primary hover:bg-troo-white hover:text-troo-secondary"
              >
                LEARN MORE
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ServiceData } from "../Data/ServiceData";
import { BsCheckLg } from "react-icons/bs";
import DetailsImg from "../images/DetailsImg.png";
import { ServiceProcessData } from "../Data/ServiceProcessData";
import { Link } from "react-router-dom";
import detailsSideImg from "../images/detailsSideImg.png";
import { motion } from "framer-motion";

const ServiceDetails = () => {
  const [picture, setPicture] = useState("");
  const [scarchparam] = useSearchParams();
  const id = Number(scarchparam.get("id"));

  useEffect(() => {
    const filData = ServiceData.find((data) => data?.id === id);
    setPicture(filData?.img);
  }, [id]);

  return (
    <div className="py-12 lg:py-24 w-full">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[75%] flex flex-col items-start gap-5">
            <img
              className="w-full"
              src={picture || DetailsImg}
              alt="DetailsImg"
            />
            <h3>Description</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free Lorem ipsum dolor sit amet,
              consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free text dolore magna aliqua lonm
              andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit
              sed do eiusmod tempor incididunt ut labore et simply free text
              dolore magna aliqua lonm andhn.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum dolor sit amet,
              consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simpl.
            </p>
            <h3>Service Process</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum dolor.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 relative z-[1]">
              {ServiceProcessData.map((e, i) => (
            <motion.div
            initial={{ x: 50, y: 50, opacity: 0, scale: 0.8 }}
          whileInView={{ x: 0, y: 0, opacity: 1, scale: 1 }}
          transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
            >
                  <Link
                  className="p-5 flex gap-5 bg-troo-tertiary rounded"
                  key={i}
                >
                  <div>
                    <h3 className="rounded-full  bg-troo-white  w-12 h-12 flex justify-center items-center text-troo-secondary">
                      0{e.id}
                    </h3>
                  </div>
                  <div className="flex flex-col gap-3">
                    <h4>{e.name}</h4>
                    <p>{e.para}</p>
                  </div>
                </Link>
            </motion.div>

              ))}
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum.
            </p>
            <div className="flex flex-col md:flex-row gap-10 md:items-center w-full">
              <div>
                <img
                  className="w-full"
                  src={detailsSideImg}
                  alt="details img"
                />
              </div>
              <div className="flex flex-col gap-3 mr-5">
                <p className="max-w-[400px] mb-5">
                  Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor and
                  it ji sit amet, consectetur notted adipisicing elit sed do
                  eiusmod tempor incididunt ut labore simply free Lorem.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                  Easy to Use, Built Technology
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                  Easy to Built onPowerful Technology
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                  Easy to Use, Built onPowerful
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                  Easy to Use, Built Technology
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:sticky top-44 lg:w-[25%]  ">
            <div className="flex flex-col items-start gap-6 bg-troo-tertiary p-8 mb-10 rounded">
              <h4 className="relative  pb-3 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-[3px] before:bg-troo-secondary">
                Other Services
              </h4>
              <div className="flex flex-col gap-3">
                {ServiceData.slice(0, 6).map((e, i) => (
                  <motion.div
                    initial={{ x: 100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: "100",
                      delay: 0.3 * i,
                    }}
                  >
                    <Link
                      key={i}
                      className="relative before:content-[''] before:absolute before:-left-4 before:w-2 before:h-2 before:bg-troo-secondary before:rounded-full before:-translate-y-1/2 before:top-1/2 before:scale-0 hover:before:scale-100 before:transition-all before:ease-in-out before:duration-500 hover:text-troo-secondary transition-all duration-500 ease-in-out text-lg font-semibold"
                    >
                      {e.name}
                    </Link>
                  </motion.div>
                ))}
              </div>
            </div>
            <div className="flex  flex-col items-start gap-6 bg-troo-primary text-troo-white p-8 mb-10 rounded before:content-[''] before:absolute relative before:bg-detailscard-bg before:bg-no-repeat before:w-full before:top-0 before:left-0 before:h-full ">
              <h4 className="relative  pb-3 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-[3px] before:bg-troo-secondary">
                Need Any Help?
              </h4>
              <div className="flex flex-col gap-3">
                <p className="font-bold">Call us</p>
                <p>+44 123 456 7890</p>
                <p className="font-bold">Email Us</p>
                <p>troohandyman@email.com</p>
                <div className="mt-7">
                  <Link className="btn1 bg-troo-white hover:bg-troo-secondary text-troo-black">
                    LEARN MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;

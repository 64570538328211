import React, { useState } from "react";

const Accordian = ({ title, desc, bgColor, isOpen }) => {
  const [isActive, setIsActive] = useState(isOpen ? true : false);

  const handleAccordionClick = (e) => {
    setIsActive(e.target.checked && !isActive);
  };
  return (
    <div
      className={`collapse collapse-arrow border border-troo-primary border-opacity-20 cursor-pointer rounded ${
        isActive
          ? "bg-troo-white text-troo-black collapse-open"
          : `${
              bgColor || ""
            } text-troo-stateTertiary collapse-close`
      }`}
    >
      <input type="radio" name="my-accordion-1" onClick={handleAccordionClick} checked="checked" />
      <div
        className={`collapse-title text-troo-deepblack font-Montserrat font-bold px-3 md:px-4
         ${isActive ? " text-troo-secondary active mb-3 " : ""}`}
      >
        <h4 className={`pr-8 flex items-center gap-3 ${isActive ? "pb-3 border-b" : ""}`}><span className="w-8 h-8 rounded-full bg-troo-primary bg-opacity-10 flex justify-center items-center">?</span>{title}</h4>
      </div>
      <div className="collapse-content px-4 flex gap-3">
        <p >{desc}</p>
      </div>
    </div>
  );
};

export default Accordian;

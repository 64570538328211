import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Brands from '../../Components/Brands'
import FAQ from '../../Components/FAQ'
import useDocumentTitle from '../../Hooks/PageTitle'

const FAQpage = () => {
  useDocumentTitle("TRoo Logistics Transportation | FAQs Page")
  return (
   <Layout>
    <BannerGlobal/>
    <FAQ/>
    <Brands/>
   </Layout>
  )
}

export default FAQpage
import React, { useEffect, useState } from "react";
import teamDetailsImg from "../images/teamDetailsImg.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const TeamDetails = () => {
  const [heading, setHeading] = useState("");
  const [picture, setPicture] = useState("");
  const [sub, setSub] = useState("");
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");
  useEffect(() => {
    const filData = TeamData.find((data) => data?.id == id);
    setPicture(filData?.img);
    setHeading(filData?.name);
    setSub(filData?.sub);
  }, [id]);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex gap-12 justify-between ">
          <div className="flex flex-col gap-5 w-full lg:w-[40%]">
            <div>
              <img
                className="w-full lg:max-w-[500px] lg:max-h-[400px] "
                src={picture || teamDetailsImg}
                alt={teamDetailsImg}
              />
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <h4> Hello, I am {heading || "Harold Franky"} </h4>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
              </div>
              <div className="inline-flex ">
                <div className="flex p-3 gap-3 bg-troo-black rounded">
                <FaFacebookF className="rounded-full w-8 p-2  h-8 bg-troo-white flex justify-center items-center hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-500" />
                <FaInstagram className="rounded-full w-8 p-2  h-8 bg-troo-white flex justify-center items-center hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-500" />
                <FaLinkedinIn className="rounded-full w-8 p-2  h-8 bg-troo-white flex justify-center items-center hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-500" />
                <FaTwitter className="rounded-full w-8 p-2  h-8 bg-troo-white flex justify-center items-center hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-500" />
                </div>
              </div>
            </div>
          </div>
          
          <div className="flex flex-col gap-5 w-full lg:w-[60%]">
            <h3>Short biography</h3>
            <p>
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.
            </p>
            <h3>Skills</h3>
            <p>
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
            </p>
            <h3>Expertise & Skills</h3>
            <div className="flex flex-col justify-around mt-10">
              <div className="flex flex-col gap-3 mb-5">
                <h4>Experience</h4>
                <div className="h-[6px] rounded w-full bg-troo-secondary bg-opacity-10">
                  <div className="h-[6px] rounded w-[90%] bg-troo-secondary"></div>
                </div>
              </div>
              <div className="flex flex-col gap-4 mb-5">
                <h4>Complited Projects</h4>
                <div className="h-[6px] rounded w-full bg-troo-secondary bg-opacity-10">
                  <div className="h-[6px] rounded w-[70%] bg-troo-secondary"></div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <h4>Client’s Setisfied</h4>
                <div className="h-[6px] rounded w-full bg-troo-secondary bg-opacity-10">
                  <div className="h-[6px] rounded w-[80%] bg-troo-secondary"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;

export const FaqData = [
  {
    id: 1,
    name: "What is a delivery automation platform?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  {
    id: 2,
    name: "Why is ePOD must for your delivery business?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  {
    id: 3,
    name: "What are the GST charges on Fare amount ?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  {
    id: 4,
    name: "How is ETA calculated?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  {
    id: 5,
    name: "Do you provide credit facility on Payments?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  {
    id: 6,
    name: "How is ETA for your food delivery calculated?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  {
    id: 7,
    name: "What is auto allocation of orders?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  {
    id: 8,
    name: "Which is the best logistics software?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  {
    id: 9,
    name: "How will I receive my invoice?",
    para: "Lorem Ipsum is simply dum rummy dummy text of the printing and typesetting’s the industry's standard dummy text ever since the 150 when and printed galley of type. Ipsum is simply.",
  },
  
];

import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { Link } from "react-router-dom";
import Accordian from "./Accordian";
import { FaqData } from "../Data/FAQsData";
import { HiOutlineMail } from "react-icons/hi";
import { TbPhoneCall } from "react-icons/tb";
import { motion } from "framer-motion";

const FAQ = ({ isHome }) => {
  const[data,setData] =useState([])
  useEffect(()=>{
    if(isHome){
      setData(FaqData.slice(0,4))
    }
    else{
      setData(FaqData)
    }
  },[])
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        {isHome && (
          <div className="flex flex-col sm:flex-row gap-5 justify-between mb-12">
            <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 2, type: "spring" }}
            >
              <Heading
                title={"How can we help?"}
                heading={"Frequently asked questions"}
              />
            </motion.div>
            <motion.div
            initial={{ x: 200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 2, type: "spring" }}
            className="flex sm:justify-end items-end">
              <Link to="/faq" className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary">
                VIEW ALL FAQ’s
              </Link>
            </motion.div>
          </div>
        )}
        <div className="grid grid-col lg:grid-cols-2 gap-8">
          <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring" }}
          className="flex flex-col gap-5 items-start relative z-[1]">
            {data.map((data, i) => {
              return (
                <Accordian
                  key={i}
                  title={data.name}
                  desc={data.para}
                  isOpen={i === 0 ? true : false}
                />
              );
            })}
          </motion.div>
          <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring" }}
          className="flex flex-col gap-5 items-start relative z-[1]">
            {data.map((data, i) => {
              return (
                <Accordian
                  key={i}
                  title={data.name}
                  desc={data.para}
                  isOpen={i === 0 ? true : false}
                />
              );
            })}
          </motion.div>
        </div>
        <div className="text-center mt-12">
          <h3 className=" ">Still you have a questions? </h3>
          <h3 className="text-2xl text-troo-secondary">Contact with us on</h3>
        </div>
        <div className="flex justify-center mt-6">
          <div className="inline-flex flex-col gap-5 md:flex-row gap-y-3 justify-center md:justify-between p-5 bg-troo-secondary text-troo-white ">
            <div className="flex gap-3">
              <div className="bg-troo-white bg-opacity-20 p-3 flex  items-center rounded">
                <TbPhoneCall className="text-xl text-troo-white" />
              </div>
              <div className="">
                <h6>Call Us on</h6>
                <p className="font-bold">+44 123 456 7890</p>
              </div>
            </div>
            <div className="border-l border-troo-secondary"></div>
            <div className="flex gap-3">
              <div className="bg-troo-white bg-opacity-20 p-3 flex  items-center rounded">
                <HiOutlineMail className="text-xl text-troo-white" />
              </div>
              <div className="">
                <h6>Email Us on</h6>
                <p className="font-bold">info@troothemes.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;

import process1 from "../images/wordIcon1.svg"
import process2 from "../images/wordIcon2.svg"
import process3 from "../images/wordIcon3.svg"
import process4 from "../images/wordIcon4.svg"

export const WorkProcessData = [
    {
        id : 1,
        img : process1,
        name : "Place Your Order",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting. "
    },
    {
        id : 2,
        img : process2,
        name : "Payment of Order",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting. "
    },
    {
        id : 3,
        img : process3,
        name : "Item Picked & Shipped",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting. "
    },
    {
        id : 4,
        img : process4,
        name : "Delivered Success",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting. "
    },
]
import React, { useEffect, useState } from "react";
import projectDetails from "../images/projectDetailsImg.png";
import { useSearchParams } from "react-router-dom";
import { BlogData } from "../Data/BlogData";
import { BsArrowLeft, BsArrowRight, BsChatDots, BsCheckLg, BsPerson, BsTelephone } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaQuoteLeft,
  FaTwitter,
} from "react-icons/fa";
import blogDetails1 from "../images/blogDetails1.png";
import blogDetails2 from "../images/blogDetails2.png";
import blogDetails3 from "../images/blogDetails3.png";
import Input from "./Input";
import { IoMailOpenOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

const BlogDetails = () => {
  const [picture, setPicture] = useState("");
  const [scarchparam] = useSearchParams();
  const id = Number(scarchparam.get("id"));

  useEffect(() => {
    const filData = BlogData.find((data) => data?.id === id);
    setPicture(filData?.img);
  }, [id]);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-8">
          <div className="relative">
            <img
              className="w-full"
              src={picture || projectDetails}
              alt="DetailsImg"
            />
            <div className="absolute bottom-5 left-5 p-3 bg-troo-primary text-troo-white">
              <p>
                Posted By: Admin, Published On: 21 August, 2023, Categories:
                Logistics, Transportation, Shipping
              </p>
            </div>
          </div>
          <h3>Top 3 Logistics Outsourcing Mistakes You Don’t Want to Make</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet.
          </p>
          <h3>Features</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable..
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
              Lorem ipsum dolor sit amet.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
              Lorem ipsum dolor sit amet.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
              Lorem ipsum dolor sit amet, consectetur.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
              Lorem ipsum dolor sit amet, consectetur.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
              There Is Lorem ipsum dolor sit amet, Lorem ipsum.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
              There Is Lorem ipsum dolor sit amet, Lorem ipsum.
            </p>
          </div>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum.
          </p>
          <div className="flex justify-center">
            <div className="max-w-[800px] rounded flex flex-col gap-3 p-5 bg-troo-tertiary text-lg font-Rubik font-bold] relative before:content-[''] before:absolute before:left-0 before:w-[3px] before:rounded before:h-[80%] before:bg-troo-secondary before:top-1/2 before:-translate-y-1/2">
              <FaQuoteLeft className="text-troo-secondary" />
              <p>
                Lorem Ipsum is simply dummy text of the printin and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s, when an unknown printer took a
                galley.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="flex flex-col md:flex-row gap-8">
              <img
                className="w-full rounded"
                src={blogDetails1}
                alt="BlogDetails1"
              />
              <img
                src={blogDetails2}
                className="w-full rounded"
                alt="BlogDetails2"
              />
            </div>
            <div>
              <img
                className="w-full rounded"
                src={blogDetails3}
                alt="blogDetails3"
              />
            </div>
          </div>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words.
          </p>
          <div className="flex items-center gap-3 p-3 bg-troo-tertiary rounded">
            <p>Share This Article:</p>
            <div className="w-8 h-8 flex justify-center items-center p-1 hover:text-troo-white hover:bg-troo-secondary rounded-full transition-all duration-500 ease-in-out">
              <FaFacebookF />
            </div>
            <div className="w-8 h-8 flex justify-center items-center p-1 hover:text-troo-white hover:bg-troo-secondary rounded-full transition-all duration-500 ease-in-out">
              <FaInstagram />
            </div>
            <div className="w-8 h-8 flex justify-center items-center p-1 hover:text-troo-white hover:bg-troo-secondary rounded-full transition-all duration-500 ease-in-out">
              <FaLinkedinIn />
            </div>
            <div className="w-8 h-8 flex justify-center items-center p-1 hover:text-troo-white hover:bg-troo-secondary rounded-full transition-all duration-500 ease-in-out">
              <FaTwitter />
            </div>
          </div>
          <div className="py-3 border-t flex justify-between">
            <div className="flex items-center gap-5">
              <BsArrowLeft/>
              Prev Project
            </div>
            <div className="flex items-center gap-5">
              Next Project
              <BsArrowRight />
            </div>
          </div>
          <h3 className="mt-7">Comments (0)</h3>
            <h4>Leave A Comment</h4>
            <form className="w-full  flex flex-col items-start gap-14">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                <Input placeholder="Full Name" icon={<BsPerson />} />
                <Input
                  placeholder="Email Adress"
                  icon={<IoMailOpenOutline />}
                />
                <Input placeholder="Phone No." icon={<BsTelephone />} />
                <Input placeholder="Company Name" icon={<FiEdit />} />
                <div className="flex md:col-span-2 items-start w-full rounded px-5 py-3 justify-between border border-troo-primary border-opacity-20">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />
                  <div className="text-troo-light text-lg">
                    <BsChatDots />
                  </div>
                </div>
              </div>
              <button className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
              POST COMMENT
              </button>
            </form>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;

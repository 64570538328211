import React from "react";
import Heading from "./Heading";
import Input from "./Input";
import { BsChatDots, BsPerson, BsTelephone } from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

const Contacts = () => {
  return (
    <div className="bg-troo-tertiary py-12 lg:py-24">
      <div className="main_container1">
        <div className="mb-12">
          <Heading
            title={"We Are Professional Logistics"}
            heading={
              <>
                Have any questions in mind? <br />
                feel free contact with us.
              </>
            }
            isCenter
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <form className="w-full  flex flex-col items-start gap-14">
            <div className="w-full flex flex-col gap-6">
              <Input placeholder="Full Name" icon={<BsPerson />} />
              <Input placeholder="Email Adress" icon={<IoMailOpenOutline />} />
              <Input placeholder="Phone No." icon={<BsTelephone />} />
              <Input placeholder="Company Name" icon={<FiEdit />} />
              <div className="flex w-full rounded px-5 py-3 justify-between border border-troo-primary border-opacity-20">
                <textarea
                  rows={5}
                  placeholder="Message"
                  className="bg-transparent outline-none w-full"
                />
                <div className="text-troo-light text-lg">
                  <BsChatDots />
                </div>
              </div>
            </div>
            <button className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
              SUBMIT
            </button>
          </form>
          <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            title="gmap"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { BlogData } from "../Data/BlogData";
import { Link, createSearchParams } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { motion } from "framer-motion";

const Blogs = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(BlogData.slice(0, 2));
    } else {
      setData(BlogData);
    }
  }, []);
  return (
    <div className={`py-12 lg:py-24 ${isHome ? "bg-troo-tertiary" : ""}`}>
      <div className="main_container1">
        {isHome && (
          <motion.div
            initial={{ y: -100 }}
            whileInView={{ y: 0 }}
            transition={{ duration: 2, type: "spring" }}
            className="mb-12"
          >
            <Heading
              title={"Logistic Tips and Tricks"}
              heading={
                <>
                  What’s new in transpotation <br />
                  area to know
                </>
              }
              isCenter
            />
          </motion.div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {data.map((e, i) => (
            <div key={i}>
              <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: "100", delay:0.5 * i }}
              >
              <Link
                to={`/our-blogs/blog-details?${createSearchParams({
                  id: e.id,
                })}`}
                className="flex flex-col sm:flex-row gap-6 p-5 group hover:shadow-lg transition-all duration-500 ease-in-out"
              >
                <div className="w-[50%] mx-auto relative flex justify-center items-center">
                  <img
                    className="w-full  h-full rounded"
                    src={e.img}
                    alt={e.name}
                  />
                  <div className="absolute">
                    <BsArrowUpRight className="w-12 h-12 p-3  flex justify-center items-center rounded-full  bg-troo-white scale-0 group-hover:scale-100 transition-all duration-500 ease-in-out " />
                  </div>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex justify-center sm:justify-start gap-6">
                    <p className="p-2 bg-troo-primary text-troo-white rounded">
                      {e.date}
                    </p>
                    <p className="p-2 shadow rounded">{e.sub}</p>
                  </div>
                  <h3>{e.name}</h3>
                  <p>{e.para}</p>
                </div>
              </Link>
              </motion.div>
            </div>
          ))}
        </div>
        {isHome && (
          <div className="text-center mt-12">
            <Link
              to="/our-blogs"
              className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white "
            >
              VIEW ALL BLOGS
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;

import React from "react";
import Layout from "../Components/Layout";
import BannerGlobal from "../Components/BannerGlobal";
import About from "../Components/About";
import Highlight2 from "../Components/Highlight2";
import Highlights from "../Components/Highlights";
import WorkProgress from "../Components/WorkProgress";
import Teams from "../Components/Teams";
import Testimonial from "../Components/Testimonial";
import Brands from "../Components/Brands";
import VideoSection from "../Components/VideoSection";
import useDocumentTitle from "../Hooks/PageTitle";

const AboutPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | About Page")
  return (
    <Layout>
      <BannerGlobal />
      <About />
      <Highlights />
      <WorkProgress />
      <Highlight2 />
      <Teams isHome/>
      <Testimonial isHome/>
      <VideoSection/>
      <Brands/>
    </Layout>
  );
};

export default AboutPage;

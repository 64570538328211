import React from 'react'
import Layout from '../../Components/Layout'
import img404 from "../../images/404img.png";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../Hooks/PageTitle";


const ErrorPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | Error404");
  return (
   <Layout>
     <div className="bg-error-bg w-full bg-no-repeat bg-center py-12 lg:py-44">
      <div className="main_container1 flex justify-center">
        <div className="flex flex-col text-center gap-9">
          <div className="">
            <img
              className="lg:max-w-[400px]   mx-auto"
              src={img404}
              alt={img404}
            />
          </div>
          <h2>Sorry! page did not found</h2>
          <h3 className="max-w-[800px] text-center m-auto">
            The page you look for does not exist or another error occured please
            kindly refreash or retun to home page.
          </h3>
          <div>
            <Link
              to="/"
              className="btn1 hover:bg-troo-primary bg-troo-secondary text-troo-white "
            >
            GO TO HOMEPAGE
            </Link>
          </div>
        </div>
      </div>
    </div>
   </Layout>
  )
}

export default ErrorPage
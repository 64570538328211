import React from "react";
import highlighimg from "../images/highLightImg2.png";
import Heading from "./Heading";
import { Link } from "react-router-dom";
import { FiPhone } from "react-icons/fi";

const Highlight2 = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 ">
      <div className="relative before:hidden lg:before:block before:bg-highlightleft-Bg before:bg-no-repeat before:w-full before:h-full before:absolute before:content-[''] before:bg-cover">
        <img className="w-full h-full" src={highlighimg} alt="highlightImg" />
      </div>
      <div className="bg-none lg:bg-highlightRight-Bg  bg-no-repeat w-full h-full bg-troo-secondary flex flex-col justify-center lg:items-start items-center gap-8 px-5  pl-8 lg:pl-24 py-12">
        <div>
          <Heading
            title={"People Why Choose US"}
            heading={
              <>
                You need any help? <br />
                Get free consultation
              </>
            }
            textLight
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-8">
          <Link to="/contact-us" className="btn1 bg-troo-white">
            CONTACT US
          </Link>
          <div className="flex  gap-8 items-center">
            <div>
              <div className="w-10 h-10 bg-troo-white flex justify-center items-center p-2 text-troo-secondary outline outline-[8px] outline-troo-whitefade2 rounded-full">
                <FiPhone className="text-xl" />
              </div>
            </div>
            <div className="text-troo-white ">
              <p>Call Us On</p>
              <h4>+1 123 456 7890</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlight2;

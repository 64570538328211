import React from "react";
import { Link } from "react-router-dom";
import logo2 from "../images/Logo2.svg";
import { FaArrowUp } from "react-icons/fa";
import { HeaderData } from "../Data/HeaderData";
import { HiOutlinePhone } from "react-icons/hi";
import { CiLocationOn } from "react-icons/ci";
import { motion } from "framer-motion";

const Footer = () => {
  const site = "https://www.troothemes.com/";
  return (
    <div className="bg-footer-Bg bg-no-repeat bg-cover bg-center w-full  h-full pt-12 lg:pt-24 bg-troo-black">
      <div className="main_container1">
        <div className="flex flex-col gap-8 items-center text-troo-white pb-12 border-b border-troo-white border-opacity-10 mb-12">
          <h2>Subscribe Our Newslatter</h2>
          <p className="mx-auto max-w-[800px] text-center">
            Troothemes tech transformers are on a mission to arm creators with
            marketing materials that enhance digital presence.
          </p>
          <form className="w-full md:w-[700px] relative">
            <input
              type="text"
              placeholder="Enter your email"
              className="w-full px-10 py-5 bg-transparent border border-troo-white border-opacity-10 rounded"
            />
            <button className="absolute right-0 bg-troo-secondary hover:bg-troo-primary h-full btn1">
              SUBSCRIBE
            </button>
          </form>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 py-12 text-troo-white">
          <motion.div
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring" }}
          className="flex flex-col gap-3">
            <h3 className=" relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-white before:h-[3px] before:w-6 pb-4  ">
              About Us
            </h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration
            </p>
            <h3>Ready to get started?</h3>
            <div>
              <Link to="/contact-us" className="btn1 bg-troo-secondary hover:bg-troo-white">
                CONTACT US
              </Link>
            </div>
          </motion.div>
          <motion.div
          initial={{ y: -50, opacity:0 }}
          whileInView={{ y: 0, opacity:1 }}
          transition={{ duration: 2, type: "spring" }}
          className="flex flex-col gap-4">
            <h3>Navigtion</h3>
            <div className="flex flex-wrap gap-4">
              {HeaderData.map((e, i) => (
                <Link className="font-Rubik text-lg ">{e.title}</Link>
              ))}
            </div>
            <h3>Our Services</h3>
            <div className="flex flex-wrap gap-4">
              <Link>Air Shipping</Link>
              <Link>Road Transport</Link>
              <Link>Sea Cargo</Link>
              <Link>Warehouse</Link>
              <Link>In-house Logistics</Link>
            </div>
          </motion.div>
          <div>
            <motion.div
            initial={{ x: 200,opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 2, type: "spring" }}
            className="border-[10px] border-troo-black outline bg-troo-white bg-opacity-5 outline-1 outline-troo-whitefade rounded p-5">
              <h3 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-white before:h-[3px] before:w-6 pb-4">
                Contact Info
              </h3>
              <nav className="flex flex-col gap-4">
                <div className="flex gap-3 ">
                  <div>
                    <HiOutlinePhone className="  w-8 h-8 text-troo-white" />
                  </div>
                  <div>
                    <p>Talk to Us</p>
                    <div className="font-Poppins max-w-[250px] font-bold">
                      +1 800-999-7053 info@troothemes.com
                    </div>
                  </div>
                </div>
                <div className="flex gap-3 ">
                  <div>
                    <CiLocationOn className=" w-8 h-8 text-troo-white" />
                  </div>
                  <div>
                    <p>Our Location</p>
                    <div className="max-w-[250px] font-Poppins font-bold">
                      119 Tanglewood Lane Gulfport, MS 39503
                    </div>
                  </div>
                </div>
              </nav>
            </motion.div>
          </div>
        </div>

        <div className="relative flex flex-col lg:flex-row justify-between items-center gap-y-5 gap-5 text-troo-white py-5 border-y border-troo-white border-opacity-20">
          <Link>
            <img src={logo2} alt="logo" />
          </Link>
          <nav className="flex flex-wrap gap-5">
            <Link>Terms & Conditions</Link>
            <Link>Cookie Policy</Link>
            <Link>Privacy Policy</Link>
          </nav>
          <p>
            Copyright © 2023.{" "}
            <Link className="font-bold" to={site}>
              troothemes
            </Link>
            . All rights reserved.
          </p>
          <div className="absolute top-[-20px] rounded-full w-10 h-10 left-[50%] translate-x-[-50%] bg-troo-primary text-troo-white flex justify-center items-center">
            <FaArrowUp className="  " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { HighlightCardData } from "../Data/HighlightCardData";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const HighlightCards = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 gap-y-10 mb-20">
      {HighlightCardData.map((e, i) => (
        <motion.div
          initial={{ x: 50, y: 50, opacity: 0, scale: 0.5 }}
          whileInView={{ x: 0, y: 0, opacity: 1, scale: 1 }}
          transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
        >
          <Link
            key={i}
            className="p-8 bg-troo-white shadow-lg group text-center flex flex-col items-center gap-5 relative before:content-[''] before:absolute before:top-3 before:left-3 before:w-3 before:h-3 before:bg-troo-secondary before:rounded rounded before:transition-all before:ease-in-out before:duration-500 before:z-[-1] z-[1] hover:before:w-full hover:before:h-full hover:before:top-0 hover:before:left-0 hover:text-troo-white   "
          >
            <div className="w-20 text-3xl h-20 rounded-full bg-troo-tertiary flex justify-center items-center group-hover:bg-troo-white group-hover:text-troo-secondary">
              {e.icon}
            </div>
            <h3>{e.name}</h3>
            <p>{e.para}</p>
          </Link>
        </motion.div>
      ))}
    </div>
  );
};

export default HighlightCards;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import AboutPage from "./Routes/AboutPage";
import ServicePage from "./Routes/Service/ServicePage";
import ServiceDetailsPage from "./Routes/Service/ServiceDetailsPage";
import ProjectPage from "./Routes/Projects/ProjectPage";
import ProjectDetailsPage from "./Routes/Projects/ProjectDetailsPage";
import TeamPage from "./Routes/Pages/TeamPage";
import TeamDetailsPage from "./Routes/Pages/TeamDetailsPage";
import TestimonialPage from "./Routes/Pages/TestimonialPage";
import PricingCardPage from "./Routes/Pages/PricingCardPage";
import FAQpage from "./Routes/Pages/FAQpage";
import ErrorPage from "./Routes/Pages/ErrorPage";
import ComingSoonPage from "./Routes/Pages/ComingSoonPage";
import BlogPage from "./Routes/Blogs/BlogPage";
import BlogDetailsPage from "./Routes/Blogs/BlogDetailsPage";
import ContactPage from "./Routes/ContactPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/our-services" element={<ServicePage />} />
          <Route path="/our-services/services-details" element={<ServiceDetailsPage />} />
          <Route path="/our-projects" element={<ProjectPage />} />
          <Route path="/our-projects/project-details" element={<ProjectDetailsPage />} />
          <Route path="/our-team" element={<TeamPage />} />
          <Route path="/our-team/our-team-details" element={<TeamDetailsPage />} />
           <Route path="/testimonial" element={<TestimonialPage />} />
           <Route path="/pricing-plan" element={<PricingCardPage />} />
           <Route path="/faq" element={<FAQpage />} />
           <Route path="/error-404" element={<ErrorPage />} />
           <Route path="/coming-soon" element={<ComingSoonPage />} />
           <Route path="/our-blogs" element={<BlogPage />} />
           <Route path="/our-blogs/blog-details" element={<BlogDetailsPage />} />
           <Route path="/contact-us" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

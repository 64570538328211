export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
    heading: "About Us",
  },
  {
    title: "services",
    path: "#",
    subNav: [
      {
        subTitle: "our services",
        heading: "What We Do",
        path: "/our-services",
      },
      {
        subTitle: "services details",
        heading: "Our Recent Works",
        path: "/our-services/services-details",
      },
    ],
  },
  {
    title: "projects",
    path: "#",
    subNav: [
      {
        subTitle: "our projects",
        heading: "Our Recent Works",
        path: "/our-projects",
      },
      {
        subTitle: "project details",
        heading: "Road Freight Transport",
        path: "/our-projects/project-details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      
      {
        subTitle: "our team",
        heading: "Our Awesome Team",
        path: "/our-team",
      },
      {
        subTitle: "our team details",
        heading: "Our Awesome Team",
        path: "/our-team/our-team-details",
      },
      
      {
        subTitle: "testimonial",
        heading: "Clients Reviews",
        path: "/testimonial",
      },
      {
        subTitle: "pricing plan",
        heading: "Select Your Perfect Plan",
        path: "/pricing-plan",
      },
   
      {
        subTitle: "faq",
        heading: "Frequently Asked Questions",
        path: "/faq",
      },
      {
        subTitle: "error 404",
        path: "/error-404",
      },
      {
        subTitle: "coming soon",
        path: "/coming-soon",
      },
    ],
  },
  {
    title: " Our Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "our blogs",
        heading: "Logistic Tips & Tricks",
        path: "/our-blogs",
      },
      {
        subTitle: "blog details",
        heading: "Logistic Tips & Tricks",
        path: "/our-blogs/blog-details",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
    heading: "Get In Touch With Us",
  },
];

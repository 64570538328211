import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { ProjectData } from "../Data/ProjectData";
import { Link, createSearchParams } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { motion } from "framer-motion";

const Projects = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(ProjectData.slice(0, 4));
    } else {
      setData(ProjectData);
    }
  }, []);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        {isHome && (
          <div>
            <Heading
              title={"Recently Work Done"}
              heading={
                <>
                  Let’s check our latest project <br /> we have recently done
                </>
              }
              isCenter
            />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-12">
          {data.map((e, i) => (
            <motion.div
            initial={{ x: -50, opacity: 0}}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.2 * i }}
              key={i}
              className="bg-troo-tertiary p-3 rounded border border-troo-secondary border-opacity-10 hover:border-opacity-100 duration-500 ease-in-out transition-all"
            >
              <Link to={`/our-projects/project-details?${createSearchParams({id : e.id})}`} className=" group flex flex-col gap-5 rounded">
                <div className="flex justify-center items-center relative before:absolute before:content-[''] before:bg-troo-black before:w-full before:h-full before:bg-opacity-50 before:scale-0 group-hover:before:scale-100 before:transition-all before:duration-500 before:ease-in-out rounded">
                  <img className="rounded w-full" src={e.img} alt={e.name} />
                  <div className="absolute">
                    <BsArrowUpRight className="w-12 h-12 p-3  flex justify-center items-center rotate-90 rounded-full  bg-troo-secondary text-troo-white scale-0 group-hover:scale-100 transition-all duration-500 ease-in-out " />
                  </div>
                </div>
                <div className="group-hover:bg-troo-secondary transition-all duration-500 ease-in-out flex flex-col gap-3 p-5 bg-troo-primary rounded text-troo-white">
                  <p>{e.sub}</p>
                  <h4>{e.name}</h4>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
        <div className="text-center mt-12">
          <Link to="/our-projects" className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white ">
            VIEW ALL SERVICES
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Projects;

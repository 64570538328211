import React from "react";
import ComingSoon from "../../Components/ComingSoon";
import useDocumentTitle from "../../Hooks/PageTitle";

const ComingSoonPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | Coming Soon")
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default ComingSoonPage;

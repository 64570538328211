import React from "react";
import { FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";
import Heading from "./Heading";
import { FaPlay } from "react-icons/fa";

const VideoSection = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 video">
      <div className="bg-videoLeft-bg bg-no-repeat w-full h-full bg-cover  relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-primary before:opacity-80 before:z-[-1] z-[1]">
      <div className="py-44">
          <Link className="play" onClick={() => window.my_modal_2.showModal()}>
            <FaPlay className="h-8 w-8 text-troo-secondary" />
          </Link>
          <dialog id="my_modal_2" className="modal">
            <form
              method="dialog"
              className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
            >
              <iframe
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/DUfXdcpEfMs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </form>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
        </div>
      </div>
      <div className="bg-videoRight-bg  bg-no-repeat bg-cover w-full h-full relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:opacity-80 before:z-[-1] z-[1] after:content-[''] after:absolute after:-left-1 after:w-2 after:h-36 after:bg-troo-white after:top-1/2 after:-translate-y-1/2 after:rounded">
      <div className="flex flex-col gap-7 px-10 py-12 lg:py-32 text-troo-white">
          <Heading
            title={"We Can Help You Stand Out Your Business"}
            heading={<>Better logistics solution & <br/> services at your fingerprint</>}
            textLight
          />
          <div>
            <Link to="/pricing-plan" className="btn1 bg-troo-white text-troo-primary">FREE CONSULTATION</Link>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default VideoSection;

import React from 'react'
import Layout from '../Components/Layout'
import Banner from '../Components/Banner'
import About from '../Components/About'
import Service from '../Components/Service'
import Projects from '../Components/Projects'
import Highlights from '../Components/Highlights'
import WorkProgress from '../Components/WorkProgress'
import Highlight2 from '../Components/Highlight2'
import Teams from '../Components/Teams'
import Testimonial from '../Components/Testimonial'
import FAQ from '../Components/FAQ'
import Blogs from '../Components/Blogs'
import Brands from '../Components/Brands'
import useDocumentTitle from '../Hooks/PageTitle'

const Home = () => {
  useDocumentTitle("TRoo Logistics Transportation - React Js Template")
  return (
   <Layout>
    <Banner/>
    <About isHome/>
    <Service isHome/>
    <Projects isHome/>
    <Highlights/>
    <WorkProgress/>
    <Highlight2/>
    <Teams isHome/>
    <Testimonial isHome/>
    <FAQ isHome/>
    <Blogs isHome/>
    <Brands/>
   </Layout>
  )
}

export default Home
import React from "react";
import Heading from "./Heading";
import highlightImg from "../images/highlightImg.png";
import { HighlightCardData } from "../Data/HighlightCardData";
import { motion } from "framer-motion";

const Highlights = () => {
  return (
    <div className="py-12 lg:py-24 bg-troo-tertiary rounded-e-[150px]">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 2, type: "spring" }}
          className="flex flex-col gap-5">
            <Heading
              title={"People Why Choose US"}
              heading={
                 " Experience you can trust,Service quality you can easily count"
              }
            />
            <div>
              <img src={highlightImg} alt="highlihght" />
            </div>
          </motion.div>
          <div className="flex flex-col gap-10">
            {HighlightCardData.map((e, i) => (
              <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: "100", delay:0.3 * i }}
              className="flex gap-5" key={i}>
                <div>
                  <div className="rounded-full w-12 h-12 bg-troo-primary bg-opacity-10 flex justify-center items-center text-2xl font-extrabold ">
                    {e.icon}
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <h3>{e.name2}</h3>
                  <p>{e.para2}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlights;

import React from "react";

const Heading = ({ heading, title,textLight,isCenter }) => {
  return (
    <div className={`flex flex-col  gap-6 ${textLight? "text-troo-white" : "text-troo-black" } ${isCenter? "text-center items-center" : "text-left"}`}>
      <div className="flex gap-2 items-center">
        <div className="flex gap-1">
          <div className={`w-6 h-[2px] ${textLight? "bg-troo-white" : "bg-troo-black"} `}></div>
          <div className={`w-2 h-[2px] ${textLight? "bg-troo-white" : "bg-troo-black"} `}></div>
          <div className={`w-2 h-[2px] ${textLight? "bg-troo-white" : "bg-troo-black"} `}></div>
        </div>
        <p>{title}</p>
      </div>
      <h2 className="max-w-[750px]">{heading}</h2>
    </div>
  );
};

export default Heading;

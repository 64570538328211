import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { ServiceData } from "../Data/ServiceData";
import { Link, createSearchParams } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { motion } from "framer-motion";

const Service = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(ServiceData.slice(0, 4));
    } else {
      setData(ServiceData);
    }
  }, []);
  return (
    <div className={`py-12 lg:py-24  ${isHome ? "bg-troo-tertiary" : ""}`}>
      <div className="main_container1">
        {isHome && (
          <div>
            <Heading
              title={"What We Do foYou"}
              heading={
                <>
                  We provide one of the best of <br /> transpotation services
                </>
              }
              isCenter
            />
          </div>
        )}
        <div className="flex flex-col  gap-6 mt-12">
          {data.map((e, i) => (
            <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.3 * i }}
              key={i}
              className="flex flex-col md:flex-row items-center group justify-between gap-5 border-y border-troo-black border-opacity-10 py-5"
            >
              <div className="flex w-full gap-5">
                <h3>0{e.id}</h3>
                <div className="flex flex-col gap-3">
                  <h3>{e.name}</h3>
                  <p>{e.para}</p>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-center gap-5 w-full">
                <div>
                  <img src={e.img} alt={e.name} />
                </div>
                <Link to={`/our-services/services-details?${createSearchParams({id : e.id})}`}>
                  <BsArrowUpRight className="w-20 h-20 bg-troo-black bg-opacity-10 p-5 rounded-full group-hover:bg-troo-secondary group-hover:text-troo-white transition-all duration-500 ease-in-out group-hover:rotate-90" />
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
        {isHome && (
          <div className="text-center mt-12">
            <Link to="/our-services" className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white ">
              VIEW ALL SERVICES
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;

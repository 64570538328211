import React from "react";
import Heading from "./Heading";
import { WorkProcessData } from "../Data/WorkProcessData";
import { motion } from "framer-motion";

const WorkProgress = () => {
  return (
    <div className="bg-none lg:bg-work-Progress bg-no-repeat bg-cover w-full h-full py-12 lg:py-24">
      <div className="main_container1">
        <Heading title={"How It Work"} heading={"Our work process"} isCenter />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-20 relative before:content-[''] before:absolute before:border-t-2 before:border-troo-secondary before:hidden lg:before:block before:border-dashed before:w-[75%] before:left-1/2 before:transform before:-translate-x-1/2 ">
          {WorkProcessData.map((e, i) => (
            <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.5 * i }}
            className="flex flex-col text-center items-center gap-5 px-6 py-20 relative before:content-[''] before:absolute before:w-5 before:h-5 before:border-4 before:border-troo-secondary before:-top-2 before:rounded-full before:left-1/2 before:transform before:-translate-x-1/2 hover:before:bg-troo-secondary before:transition-all before:ease-in-out before:duration-500">
              <div className="w-40 h-40 flex justify-center items-center rounded-tl-none rounded-tr-full rounded-br-full rounded-bl-full bg-troo-secondary bg-opacity-10 rotate-45">
                <img className="-rotate-45" src={e.img} alt={e.name} />
              </div>
              <h3>{e.name}</h3>
              <p>{e.para}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkProgress;

import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Brands from "../../Components/Brands";
import TeamDetails from "../../Components/TeamDetails";
import useDocumentTitle from "../../Hooks/PageTitle";

const TeamDetailsPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | Team Details page")
  return (
    <Layout>
      <BannerGlobal />
      <TeamDetails />
      <Brands />
    </Layout>
  );
};

export default TeamDetailsPage;

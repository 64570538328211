import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { FeedbackData } from "../Data/FeedbackData";
import quote from "../images/quote.svg";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { motion } from "framer-motion";

const Testimonial = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(FeedbackData.slice(0, 3));
    } else {
      setData(FeedbackData);
    }
  }, []);
  return (
    <div className={` py-12 lg:py-24  ${isHome? "bg-feedback-bg bg-no-repeat w-full h-full bg-troo-tertiary" : ""}`}>
      <div className="main_container1">
        {isHome && (
          <motion.div
          initial={{ y: -200 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 2, type: "spring" }}
          className="mb-12">
            <Heading
              title={"Clients Testimonials"}
              heading={
                <>
                  What our valuable client say <br /> about our services
                </>
              }
              isCenter
            />
          </motion.div>
        )}

        <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {data.map((e, i) => (
            <motion.div
            initial={{ x: 50, y: 50, opacity: 0, scale: 0.5 }}
            whileInView={{ x: 0, y: 0, opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
              key={i}
              className="bg-troo-white p-8 flex flex-col gap-7 overflow-hidden relative group shadow-lg"
            >
              <div className="flex flex-col gap-3">
                <p>{e.Cdesg}</p>
                <h4>{e.name}</h4>
              </div>
              <p>{e.para}</p>
              <div className="border-t-2 border-troo-primary "></div>
              <div className="flex justify-between">
                <div className="flex gap-2">{e.rating}</div>
                <div>
                  <img src={quote} alt="quote" />
                </div>
              </div>
              <div className="testimonialImgBg bg-troo-primary border-2  border-troo-primary group-hover:bg-troo-secondary group-hover:border-troo-secondary transition-all duration-500 ease-in-out">
                <img
                  className="rounded-full rotate-[137deg]"
                  src={e.img}
                  alt={e.name}
                />
              </div>
            </motion.div>
          ))}
        </div>
        <div className="mt-12 text-xl text-troo-secondary font-Rubik font-bold flex justify-center items-center gap-3">
          <Link to="/testimonial">Read All Reviews</Link>
          <HiArrowNarrowRight className="text-2xl" />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

import React from "react";
import projectDetails from "../images/projectDetailsImg.png";
import { FaRegUser } from "react-icons/fa";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { BsArrowLeft, BsArrowRight, BsCalendar2Day } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import projectDetails1 from "../images/projectDetails1.png";
import projectDetails2 from "../images/projectDetails2.png";
import projectDetails3 from "../images/projectDetails3.png";
import { HighlightCardData } from "../Data/HighlightCardData";


const ProjectDetails = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-7">
          <h2>Road Freight Transport</h2>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined.
          </p>
          <div className="relative">
            <img className="w-full" src={projectDetails} alt="projectDetails" />
            <div className="absolute right-10 bottom-5 py-5  bg-troo-white">
              <div className="flex gap-5 py-2 border-b p-5 ">
                <FaRegUser className="rounded-full text-troo-secondary bg-troo-tertiary w-9 h-9 p-2" />
                <div className="flex flex-col">
                  <h5>Client</h5>
                  <p>Jessica Brown</p>
                </div>
              </div>
              <div className="flex gap-5 py-2 border-b p-5">
                <BiPurchaseTagAlt className="rounded-full text-troo-secondary bg-troo-tertiary w-9 h-9 p-2" />
                <div className="flex flex-col">
                  <h5>Client</h5>
                  <p>Jessica Brown</p>
                </div>
              </div>
              <div className="flex gap-5 py-2  p-5">
                <BsCalendar2Day className="rounded-full text-troo-secondary bg-troo-tertiary w-9 h-9 p-2" />
                <div className="flex flex-col">
                  <h5>Client</h5>
                  <p>Jessica Brown</p>
                </div>
              </div>
            </div>
          </div>
          <h3>Overview</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined.The majority have suffered alteration in some
            form, by injected humour, or randomised words which don't look even
            slightly believable.
          </p>
          <div className="flex flex-col gap-5">
            <p className="flex items-center gap-2">
              <FiCheckCircle className="text-troo-secondary" />
              Emergency response time is one hour or less guaranteed.
            </p>
            <p className="flex items-center gap-2">
              <FiCheckCircle className="text-troo-secondary" />
              Until the eleifend elit is a lot of great facilities.
            </p>
            <p className="flex items-center gap-2">
              <FiCheckCircle className="text-troo-secondary" />
              Lorem Ipsum is simply dummy text
            </p>
            <p className="flex items-center gap-2">
              <FiCheckCircle className="text-troo-secondary" />
              Lorem Ipsum is simply dummy text of the printing.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div>
              <img
                className="w-full"
                src={projectDetails1}
                alt="projectDetails1"
              />
            </div>
            <div>
              <img
                className="w-full"
                src={projectDetails2}
                alt="projectDetails2"
              />
            </div>
            <div>
              <img
                className="w-full"
                src={projectDetails3}
                alt="projectDetails3"
              />
            </div>
          </div>
          <h3>Why Get Our logistic Service</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined.The majority have suffered alteration in some
            form, by injected humour, or randomised words which don't look even
            slightly believable.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-12 ">
            {HighlightCardData.map((e, i) => (
              <div key={i} className="p-5 flex gap-5 hover:shadow-lg transition-all duration-500 ease-in-out">
                <div>
                  <div className="w-12 text-2xl h-12 rounded-full bg-troo-tertiary flex justify-center items-center group-hover:bg-troo-white group-hover:text-troo-secondary">
                    {e.icon}
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <h3>{e.name}</h3>
                  <p>{e.para2}</p>
                </div>
              </div>
            ))}
          </div>
          <h3>Sourcing the Best of this Projects</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined.The majority have suffered alteration in some
            form, by injected humour, or randomised words which don't look even
            slightly believable.
          </p>
          <div className="py-3 border-t flex justify-between">
            <div className="flex items-center gap-5">
              <BsArrowLeft />
              Prev Project
            </div>
            <div className="flex items-center gap-5">
              Next Project
              <BsArrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;

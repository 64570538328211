import React, { useState } from "react";
import HighlightCards from "./HighlightCards";
import aboutImg1 from "../images/aboutImg1.png";
import aboutImg2 from "../images/aboutImg2.png";
import { Link } from "react-router-dom";
import person from "../images/aboutPerson.png";
import Heading from "./Heading";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger"

const About = ({ isHome }) => {
  const [counter, setCounter] = useState(false);
  return (
    <div
      className={`main_container1  ${
        isHome ? "lg:-mt-20 pt-12 lg:pt-0 pb-12 lg:pb-24" : "py-12 lg:py-24"
      }`}
    >
      {isHome && <HighlightCards />}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="grid  grid-cols-2 gap-2 md:gap-5">
          <div className=" h-full">
            <motion.img
              initial={{ x: -50 }}
              whileInView={{ x: 0 }}
              transition={{ duration: 2, type: "spring" }}
              className="w-full h-full rounded "
              src={aboutImg1}
              alt="img2"
            />
          </div>
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 2, type: "spring" }}
            className="flex flex-col gap-2 md:gap-5 "
          >
            <div className=" h-full">
              <img
                className="w-full h-full rounded "
                src={aboutImg2}
                alt="img1"
              />
            </div>
            <div className="p-8 rounded flex flex-col items-center gap-3 bg-troo-secondary text-troo-white relative before:content-[''] before:bg-about-Bg before:bg-no-repeat before:w-full before:h-full before:absolute before:top-0 before:left-0 before:bg-cover before:bg-center">
              <h2>
              <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={52000} end={53000} duration={3} delay={0} />
                  )}
                  <strong>+</strong>
                </ScrollTrigger>
              </h2>
              
              <h4>Delivered packages.</h4>
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{ x: 200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 2, type: "spring" }}
          className="flex flex-col gap-6"
        >
          <Heading
            title={"We Are Professional Logistics"}
            heading={"We are best and trusted transportation company."}
          />
          <p className="pl-10 border-l-2 border-troo-black ">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            inta nonumy eirmod tempor invidunt ut labore et.Lorem ipsum dolor
            sit amet, consetetur.
          </p>
          <div className="flex flex-col sm:flex-row gap-5 justify-between my-5">
            <div className="flex flex-col gap-8">
              <h4>The Best Services</h4>
              <p>
                There are many variations passages Lorem Ipsum, but we are the
                we are no majority have.
              </p>
            </div>
            <div className="flex flex-col gap-8">
              <h4>Expert Team Member</h4>
              <p>
                There are many variations passages Lorem Ipsum, but we are the
                we are no majority have.
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-8">
            <div>
              <Link className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-tertiary">
                CONTACT US
              </Link>
            </div>

            <div className="flex gap-3">
              <div>
                <img
                  src={person}
                  className="rounded-full w-14 h-14 border-[4px] border-troo-white outline outline-1 outline-troo-secondary "
                  alt="aboutperson"
                />
              </div>
              <div>
                <h4>Vinita Wiliams</h4>
                <p>Vinita Wiliams</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;

import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Address from '../Components/Address'
import Contacts from '../Components/Contacts'
import useDocumentTitle from '../Hooks/PageTitle'

const ContactPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | Contact Page")
  return (
    <Layout>
        <BannerGlobal/>
        <Address/>
        <Contacts/>
    </Layout>
  )
}

export default ContactPage
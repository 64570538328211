import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Brands from "../../Components/Brands";
import ServiceDetails from "../../Components/ServiceDetails";
import useDocumentTitle from "../../Hooks/PageTitle";

const ServiceDetailsPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | Service Details")
  return (
    <Layout>
      <BannerGlobal />
      <ServiceDetails />
      <Brands />
    </Layout>
  );
};

export default ServiceDetailsPage;

import React from "react";
import { AddressData } from "../Data/AddressData";
import { motion } from "framer-motion";

const Address = () => {
  return (
    <div className="py-12 lg:py-20">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {AddressData.map((e, i) => (
            <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.3 * i }}
            className="p-5 flex items-center gap-5 border border-troo-primary border-opacity-20 rounded hover:shadow-lg transition-all ease-in-out duration-500" key={i} >
              <div>
                <div className="rounded-full bg-troo-secondary w-16 h-16 flex justify-center items-center text-troo-white text-3xl">{e.icon}</div>
              </div>
              <div className="flex flex-col gap-3">
                <p>{e.name}</p>
                <h4>{e.para}</h4>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Address;

import { BiRocket } from "react-icons/bi";
import { BsShieldCheck } from "react-icons/bs";
import { CiRoute } from "react-icons/ci";

export const HighlightCardData = [
  {
    id: 1,
    name: "Faster",
    name2 : "Real Time Tracking",
    para: "Lorem ipsum dolor sit elitr amet, consetetur sadipscing ",
    para2 : "Lorem Ipsum is simply dummy rummy text of the printing and typesetting’s the industrey's standard dummy text.",
    icon: <BiRocket />,
  },
  {
    id: 2,
    name: "Affordable Price",
    name2 : "Fast Transpotation Service",
    para: "Lorem ipsum dolor sit elitr amet, consetetur sadipscing ",
    para2 : "Lorem Ipsum is simply dummy rummy text of the printing and typesetting’s the industrey's standard dummy text.",
    icon: <BiRocket />,
  },
  {
    id: 3,
    name: "Secure",
    name2 : "Focus on Safety",
    para: "Lorem ipsum dolor sit elitr amet, consetetur sadipscing ",
    para2 : "Lorem Ipsum is simply dummy rummy text of the printing and typesetting’s the industrey's standard dummy text.",
    icon: <BsShieldCheck />,
  },
  {
    id: 4,
    name: "Global",
    name2 : "24 X 7 Online Support",
    para: "Lorem ipsum dolor sit elitr amet, consetetur sadipscing ",
    para2 : "Lorem Ipsum is simply dummy rummy text of the printing and typesetting’s the industrey's standard dummy text.",
    icon: <CiRoute />,
  },
];

import project1 from "../images/project1.png"
import project2 from "../images/project2.png"
import project3 from "../images/project3.png"
import project4 from "../images/project4.png"
import project5 from "../images/project5.png"
import project6 from "../images/project6.png"
import project7 from "../images/project7.png"
import project8 from "../images/project8.png"
import project9 from "../images/project9.png"
import project10 from "../images/project10.png"
import project11 from "../images/project11.png"
import project12 from "../images/project12.png"



export const ProjectData = [
    {
        id : 1,
        img : project1,
        name : "Road Freight Transport",
        sub : "Tranport, Logistics"
    },

    {
        id : 2,
        img : project2,
        name : "Mordern & Airship Cargo",
        sub : "Cargo, Logistics"
    },
    {
        id : 3,
        img : project3,
        name : "Ocean Transport",
        sub : "Packaging, Distribution"
    },

    {
        id : 4,
        img : project4,
        name : "Packaging & Distribution",
        sub : "Logistics, Warehouse"
    },
    {
        id : 5,
        img : project5,
        name : "Inland Warehouse",
        sub : "Warehouse"
    },
    {
        id : 6,
        img : project6,
        name : "Freight Forwarding",
        sub : "TShipping"
    },
    {
        id : 7,
        img : project7,
        name : "Supply Chain",
        sub : "Tracking"
    },
    {
        id : 8,
        img : project8,
        name : "Delivery Administration",
        sub : "Logistic Transport"
    },
    {
        id : 9,
        img : project9,
        name : "Shipment Tracking",
        sub : "Tranport, Logistics"
    },
    {
        id : 10,
        img : project10,
        name : "Mordern & Airship Cargo",
        sub : "Cargo, Logistics"
    },
    {
        id : 11,
        img : project11,
        name : "Supply Chain",
        sub : "Logistic Transport"
    },
    {
        id : 12,
        img : project12,
        name : "Warehouse Inventory",
        sub : "Logistics, Warehouse"
    },
    
]

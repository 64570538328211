import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Brands from "../../Components/Brands";
import PricingCards from "../../Components/PricingCards";
import useDocumentTitle from "../../Hooks/PageTitle";

const PricingCardPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | Pricing Card Page")
  return (
    <Layout>
      <BannerGlobal />
      <PricingCards />
      <Brands />
    </Layout>
  );
};

export default PricingCardPage;

import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Brands from '../../Components/Brands'
import BlogDetails from '../../Components/BlogDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogDetailsPage = () => {
  useDocumentTitle("TRoo Logistics Transportation | Blog Details")
  return (
    <Layout>
        <BannerGlobal/>
        <BlogDetails/>
        <Brands/>
    </Layout>
  )
}

export default BlogDetailsPage
import React from "react";
import { PricingData } from "../Data/PricingData";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const PricingCards = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-8 relative z-[1]">
          {PricingData.map((e, i) => (
            <motion.div
            initial={{ x: 50, y: 50, opacity: 0, scale: 0.5 }}
            whileInView={{ x: 0, y: 0, opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
              key={i}
              className="p-10 flex flex-col items-center gap-7  relative before:content-[''] bg-pricing-bg w-full h-full border bg-cover  bg-no-repeat group  "
            >
              <h3>{e.name}</h3>
              <div>
                <div className="flex items-end relative pl-5">
                  <h2>${e.price}</h2>
                  <p> /Month </p>
                  <h6 className="absolute left-0 top-0 font-bold">$</h6>
                </div>
              </div>
              <h4>Features</h4>
              <div className="flex  items-start gap-3 self-center p-5 flex-col bg-troo-tertiary">
                {e.feture.map((ele, ind) => (
                  <p key={ind} className="flex items-center gap-2 ">
                    {ele.data}
                  </p>
                ))}
              </div>
              <Link className="btn1 border border-troo-primary  bg-troo-white group-hover:bg-troo-secondary group-hover:text-troo-white transition-all duration-500 ease-in-out">
              CHOOSE THIS PLAN
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingCards;
